/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  FaqModal.module.css
*/

.brand {
  height: 20px;
}
.question {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.answer {
  padding-left: 15px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
