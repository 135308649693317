/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SearchWindow.module.css
*/

.container {
  background-color: rgb(250, 249, 245);
  /* padding: 10px; */
  border-radius: 5px;
  box-shadow: 0px 0px 5px gray;
  margin-bottom: 15px;
}

.header {
  padding-top: 10px;
}

.title {
  font-size: 1.4rem;
  font-weight: 400;
  padding-left: 10px; padding-top: 10px;
}

.tabs {
  border-bottom: solid #ebe6d3;
  padding-left: 10px; padding-right: 10px;
}

.tab {
  border-style: none;
  background-color: rgba(118, 23, 23, 0);
  padding: 5px;
  padding-left: 7px; padding-right: 7px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.2s ease-in-out;
  color: black;
}

.tab.active {
  background-color: rgb(76, 97, 105);
  color: white;
}

.searchWrapper {
  padding: 10px; padding-bottom: 0px;
}

.contentWrapper {
  padding: 10px; padding-top: 0px;
}

.content {
  height: 65vh;
  overflow: scroll;
  background-color: #fcfbf9;
  border-radius: 5px;
  box-shadow: 0px 0px 3px gray;
  margin-bottom: 10px;
}

.bottomText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.631);
}

.emptyWrapper {
}
.empty {
  padding: 15px;
}
.emptyTitle {
  font-size: 1.4rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .emptyWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
  }
  .empty {
    width: 500px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
