/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Faq.module.css
*/

.main {
  padding: 15px;
  padding-top: 30px;
}

.item {
  margin-bottom: 30px;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.question {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .container {
    display: flex;
    justify-content: center;
  }
  .main {
    width: 600px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
