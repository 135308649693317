/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  StarsForm.module.css
*/

.container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.starsContainer {
  display: flex;
  gap: 4px;
}

.starWrapper {
  padding: 10px;
  border-style: none;
  border-radius: 7px;
  background-color: #d0dedc;
  transition: all 0.15s ease-in-out;
}
.starWrapper:active {
  transform: scale(0.9);
}

.rating {
  margin: 0px;
  color: #00000080;
  position: relative;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
