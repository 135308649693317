/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  InfoPopover.css
*/
.ip-question-btn.btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 0px;
  border-width: 2px;
  border-color: #6c757d;
  position: relative;
}

.ip-question-btn .bi-question {
  position: relative;
  top: -5px;
  font-size: 16px;
  color: #6c757d;
}

.ip-question-popover {
  color: black;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
