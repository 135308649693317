.container {
  padding: 10px;
}

.titleContainer {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}

.title {
  font-size: 1.2rem;
}
.titleCount {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.365);
}

.writeButton {
  background-color: #3f8070;
  border-color: #3c7c6d;
}
.writeButton:active {
  background-color: #71a094;
}
.writeButton.precep {
  background-color: #4c6169;
  border-color: #3f5057;
}
.writeButton.precep:active {
  background-color: #697f88;
}

.notice {
  font-size: 0.9rem;
  color: gray;
}

.reviewContainer {
  background-color: #ffffff;
  box-shadow: 0px 0px 3px gray;
  margin-bottom: 10px;
  border-radius: 5px;
}

.reviewHeader {
  padding: 10px;
  border-bottom-style: solid;
  border-bottom-color: rgb(180, 180, 180);
  border-bottom-width: 1px;
  display: flex;
}
.reviewAuthorContainer {
  flex: 1;
}
.reviewAuthorWrapper {
  display: flex;
  gap: 2px;
  align-items: center;
}
.authorIcon {
  color: rgba(0, 0, 0, 0.658);
}
.reviewAuthor {
  font-size: 1rem;
  margin: 0px;
}
.reviewDate {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.383);
}

.reviewRatings {
  /* flex: 1; */
}
.reviewRating {
  padding: 0px;
  margin: 0px;
  display: flex;
  gap: 10px;
  margin-bottom: 2px;
}

.textContainer {
  padding: 10px;
}
.text {
  margin: 0px;
}

.moreButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
