
/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  CreateForm.module.css
*/

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.addPreceptor {
  margin-bottom: 10px;
  font-size: 0.8rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
