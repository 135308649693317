/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Features.module.css
*/

.main {
  color: white;
  background-color: #3f8070;
  padding: 15px;
  padding-top: 30px;
  text-align: center;
}

.title {
  margin-bottom: 30px;
  font-weight: 700;
}

.feature {
  margin-bottom: 30px;
}

.icon {
  font-size: 40px !important;
}
.starIcon {
  margin-top: 10px;
  font-size: 20px !important;
  margin-bottom: 10px;
}
/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .feature {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
  }
}


@media only screen and (min-width: 992px) {
  .feature {
    /* width: 33%; */
    padding-left: 50px;
    padding-right: 50px;
    /* background-color: pink; */
  }
}


@media only screen and (min-width: 1200px) {
  .features {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .feature {
    width: 33%;
  }
}
