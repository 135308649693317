/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Footer.module.css
*/

.container {
  width: 100%;
  /* background-color: #f5f3e9; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  padding-top: 20px; padding-bottom: 20px;
  padding-left: 20px; padding-right: 10px;
  box-shadow: 0px 0px 10px #c8c8c8;
}
.brand {
  height: 20px;
}
.igLogo {
  height: 1.3rem;
  margin-right: 4px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .container {
    height: 3rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px; padding-bottom: 0px;
    padding-left: 20px; padding-right: 20px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
