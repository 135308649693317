/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  VerifyEmail.module.css
*/

.main {
  margin-top: 100px;
}

.h1 {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .main {
    margin: 0px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
