/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  NpiLookup.module.css
*/

.titleWrapper {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.xBtn {
  border-style: none;
  background-color: #efecde00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px; width: 25px;
}
.xBtn:hover {
  background-color: #efecde;
}
.xBtn:active {
  background-color: #e4e0cc;
}
.xBtn * {
  font-size: 15px;
  color: #00000073;
}

.pData {
  background-color: #edf2f3;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.name {
  font-size: 1rem;
  margin-bottom: 5px;
}

.infoItem {
  margin: 0px;
  font-size: 0.8rem;
}

.infoState {
  font-weight: 600;
}

.btnContainer {
  margin-top: 10px;
  display: flex;
  gap: 5px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
