/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PromptPage.module.css
*/

.page {
  padding: 10px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  max-width: 300px;
}

.btn {
  width: 100%;
  border-radius: 10px;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: none;
  border-style: solid;
  border-width: 3px;
  transition: all 0.15s ease-in-out;
}
.btn.rotation {
  background-color: #3f8070;
  border-color: #316357;
}
.btn.rotation:active {
  background-color: #356e60
}
.btn.preceptors {
  background-color: #0e2d37;
  border-color: #0a2028;
}
.btn.preceptors:active {
  background-color: #091d23;
}
.btnTitle {
  font-size: 1.3rem;
}
.btnData p {
  margin: 0px;
}
.btnDataH1 {
  font-weight: 700;
}
.btnDataH2 {
  font-size: 0.8rem;
}

.noThanks {
  text-align: center;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .headerWrapper {
    display: flex;
    justify-content: center;
  }
  .header {
    width: 400px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
