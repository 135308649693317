/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SearchBar.module.css
*/

.inputGroup {
  margin-bottom: 10px;
}

.filterButton {
  background-color: #4c6169;
  border-color: #3b4c52;
}
.filterButton:hover {
  background-color: #3c4d54;
}
.filterButton:active {
  background-color: #293439;
}

.anchor {
  height: 0px;
  width: 100%;
  position: relative;
  transition: all 0.15s ease-in-out;
}
.filterOptions {
  position: absolute;
  top: 8px;
  right: 0px;
  width: 96%;
  background-color: white;
  box-shadow: 0px 0px 5px gray;
  border-radius: 5px;
  z-index: 10;
  /* padding: 10px; */
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: auto;
}
.filterOptions.hidden {
  opacity: 0;
  pointer-events: none;
}
.tabs {
  margin-bottom: 0px;
}
.tab {
  min-height: 200px;
  overflow: scroll;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 10px;
}

.filters {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.filter {
  padding: 3px;
  padding-left: 10px; padding-right: 5px;
  border-radius: 1.3rem;
  background-color: #d3dadc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}
.filter.shrink {
  flex: 1;
}
.filterBtn {
  background-color: #884f4f00;
  border-style: none;
  color: gray;
  font-size: 1.3rem;
  padding: 0px;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.filterBtn:active {
  background-color: #cbd2d4
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .filterOptions {
    width: 250px;
  }
  .tab {
    height: 200px;
  }
  .filters {
    flex-wrap: nowrap;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
