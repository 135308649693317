/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SmallStars.css
*/

.container {
  display: flex;
}

.singleContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

.starCount {
  color: #ab9d01;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
