/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AppStyles.module.css
*/

.link {
  color: #3f8070;
  /* text-decoration: underline; */
  cursor: pointer;
}
.link:hover {
  color: #387163;
}

.h1, .h2, .h3 {
  margin-bottom: 2px;
}
.h1 {
  font-size: 1.8rem;
  font-weight: 700;
}
.h2 {
  font-size: 1.2rem;
  font-weight: 600;
}
.h3 {
  font-size: 0.8rem;
  font-weight: 500;
}

.primaryBtn {
  background-color: #3f8070;
  border-color: #3c7c6d;
}
.primaryBtn:disabled {
  background-color: #498072;
  border-color: #3c7c6d;
}
.primaryBtn:hover {
  background-color: #38695d;
}
.primaryBtn:active {
  background-color: #71a094;
}
.primaryBtn.precep {
  background-color: #4c6169;
  border-color: #3f5057;
}
.primaryBtn.precep:active {
  background-color: #697f88;
}
.checkBox input:checked {
  background-color: #498072;
  border-color: #3c7c6d;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
