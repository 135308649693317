/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Template.css
*/

.suggestionsWrapper {
  position: absolute;
  width: 100%;
  height: 0px;
  top: 2.3rem;
  z-index: 10;
}

.suggestions {
  margin-top: 5px;
  min-height: 2rem;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 4px gray;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.suggestionBtn {
  background-color: rgba(255, 255, 255, 0);
  border-style: none;
  padding: 10px;
  padding-left: 15px;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: black;
}
.suggestionBtn:hover {
  background-color: rgb(238, 238, 238);
}
.empty:hover {
  background-color: rgba(255, 255, 255, 0);
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
