/* Screen size specs */

.alert-confirm-code-instructions {
    padding: 0px;
    margin: 10px;
    color: gray;
}

.alert-modal {
    color: black;
}

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
