/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ReviewModal.module.css
*/

.header {
}
.h1, .h2, .h3 {
  margin-bottom: 2px;
}
.h1 {
  font-size: 1.4rem;
}
.h2 {
  font-size: 1rem;
}
.h3 {
  font-size: 0.8rem;
}

.sec1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.col1 {
  flex: 1;
}
.authorWrapper {
  display: flex;
  gap: 2px;
  align-items: center;
}
.authorIcon {
  color: rgba(0, 0, 0, 0.658);
}
.author {
  font-size: 1rem;
  margin: 0px;
}
.date {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.383);
  margin-bottom: 2px;
}
.stat {
  display: flex;
  gap: 5px;
  align-items: center;
}
.statLabel, .statVal {
  margin: 0px;
  font-size: 0.8rem;
}

.col2 {
  /* flex: 1; */
}
.reviewRating {
  padding: 0px;
  margin: 0px;
  display: flex;
  gap: 10px;
  margin-bottom: 2px;
}

.sec1_1 {
  margin-bottom: 15px;
}

.sec2 {
  margin-bottom: 15px;
}
.flagsContainer {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
}
.flags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.flag {
  font-size: 0.8rem;
  background-color: #f5f3e9;
  padding: 2px; padding-left: 7px; padding-right: 7px;
  border-radius: 10px;
}
.strength {
  background-color: #dee8e9;
}
.weakness {
  background-color: #efd8e1;
}

.longFormLabel {
  font-size: 1rem;
  margin-bottom: 2px;
}
.review {
  font-size: 0.9rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
