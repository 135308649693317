
/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Mission.module.css
*/

.main {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  padding: 15px;
}

.title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.subtitle {
  color: #3f8070;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .container {
    display: flex;
    justify-content: center;
  }
  .main {
    width: 650px;
  }
}


@media only screen and (min-width: 992px) {
  .main {
    width: 800px;
  }
}


@media only screen and (min-width: 1200px) {
  .main {
    /* width: 1000px; */
  }
}
