/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AccountPage.module.css
*/

.page {
  padding: 10px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .formContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  }
  .formWrapper {
    width: 500px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
