/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  BinaryInput.module.css
*/

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.option {
  flex: 1;
  border-style: none;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
