/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Banner.module.css
*/

.banner {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  background-image: linear-gradient(rgb(63, 128, 112), rgb(250, 249, 245));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-top: 120px;
}

.content {
}

.brand {
  filter: invert(100%);
  margin-bottom: 10px;
}

.slogan {
  color: white;
  font-weight: 200;
  font-size: 23px;
}

.btnWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.btnWrapper a {
  font-size: 1.2rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
