/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Loading.css
*/
.authSpinnerContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  /* position: relative; */
}

.outer, .inner, .moat, .target {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.inner {
  background-color: black;
  border-radius: 50%;
  animation: inner 1.5s infinite ease-in;
}
.moat, .target {
  background-color: #faf9f5;
  border-radius: 50%;
}
.moat, .inner, .target {
  z-index: 100;
}
.outer {
  height: 200px;
  width: 200px;
  position: relative;
}
.semi1, .semi2 {
  position: absolute;
  top: 0px;
  height: 100px;
  width: 200px;
  border-radius: 100px 100px 0 0;
  background-color: black;
}
.semi1 {
  animation: semi1 1.5s infinite linear;
  z-index: 5;
}
.semi2 {
  transform: rotate(180deg);
  top: 100px;
  animation: semi2 1.5s infinite linear;
  z-index: 3;
}
.cover1, .cover2, .cover0 {
  height: 210px;
  width: 210px;
  transition: transform 0.3s ease-in-out;
  border-style: solid;
  border-width: 50px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  border-top-color: #faf9f5;
  border-right-color: #faf9f5;
  border-bottom-color: transparent;
  border-left-color: transparent;
  /* opacity: 0.8; */
}
.cover0 {
  border-top-color: transparent;
  opacity: 1;
  z-index: 10;
}
.cover1 {
  transform: rotate(-225deg);
  /* border-top-color: pink;
  border-right-color: pink; */
  animation: cover1 1.5s infinite ease-in;
  z-index: 2;
}
.cover2 {
  transform: rotate(0deg);
  /* border-top-color: lightblue;
  border-right-color: lightblue; */
  animation: cover2 1.5s infinite ease-out;
  z-index: 4;
}
.moat {
  height: 130px;
  width: 130px;
}
.inner {
  height: 80px;
  width: 80px;
}
.target {
  height: 35px;
  width: 35px;
}

@keyframes cover1 {
  0% {
    transform: rotate(-90deg);
    z-index: 2;
  }
  40% {
    transform: rotate(-225deg);
    z-index: 2;
  }
}
@keyframes semi1 {
  0% {z-index: 1;}
  39% {z-index: 1;}
  40% {z-index: 5;}
  80% {opacity: 1}
  100% {opacity: 0}
}
@keyframes cover2 {
  0% {
    transform: rotate(135deg);
    z-index: 4;
  }
  40% {
    transform: rotate(135deg);
    z-index: 4;
  }
  80% {
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes semi2 {
  0% {z-index: 3}
  40% {z-index: 3;}
  80% {
    z-index: 3;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes inner {
  0% {opacity: 0}
  20% {opacity: 0}
  60% {opacity: 1}
  80% {opacity: 1}
  100% {opacity: 0}
}
  
/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
