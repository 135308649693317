/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AuthPage.module.css
*/
/* Screen size specs */

.main {
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  padding-bottom: 60px;
}

.brand {
  width: 300px;
  margin-bottom: 5px
}

.header {
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .main {
    padding-top: 0px;
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content {
    width: 400px;
  }

  .authContainer {
    /* background-color: white; */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 4px rgb(218, 218, 218);
  }
}


@media only screen and (min-width: 1200px) {
}
