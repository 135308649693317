.container {
  position: relative;
}

.navWrapper {
  position: absolute;
  top: 0px;
  width: 100%;
}

.content {
  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.cover {
  width: 100%;
  object-fit: cover;
  height: 200px;
  background-image: linear-gradient(#71a094, #3f8070);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.cover.precep {
  background-image: linear-gradient(#4c6169, #0e2d37);
  padding-bottom: 40px;
}

.title {
  font-size: 2rem;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}

.instWrapper {
  position: relative;
  width: 100%;
}
.institution {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}
.institution.precep {
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: all 1s;
}
.institution.shown {
  opacity: 1;
}


.location {
  color: white;
  font-size: 0.8rem;
}

.data {
  padding: 12px;
}

.pair {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}
.pair.edges {
  justify-content: space-between;
  gap: 0px;
}

.flexLabelWrapper {
  display: flex;
  align-items: center;
}
.labelWrapper {
  margin-bottom: 1px;
}
.labelIcon {
  color: black;
  margin-right: 5px;
}
.label {
  font-weight: 600;
  font-size: 1rem;
  margin: 0px;
}
.value {
  margin: 0px;
}
.value a {
  color: #3f8070;
}

.flagsContainer {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
}
.flags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.flag, .strength {
  /* font-size: 0.8rem; */
  background-color: #f5f3e9;
  padding: 2px; padding-left: 7px; padding-right: 7px;
  border-radius: 10px;
}
.weakness {
  background-color: #efd8e1;
}
.strength {
  background-color: #dee8e9;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .contentWrapper {
    display: flex;
    justify-content: center;
  }
  .content {
    width: 450px;
  }
  .cover.precep {
    padding-bottom: 50px;
  }
}


@media only screen and (min-width: 1024px) {
  .content {
    width: 850px;
    display: flex;
    gap: 15px;
  }
  .data, .reviews {
    flex: 1;
  }
}


@media only screen and (min-width: 1200px) {
}
