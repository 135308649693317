/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Star.module.css
*/

.starWrapper {
  /* background-color: red; */
}
.star {
  position: relative;
  display: block;
  color: red;
  width: 0px;
  height: 0px;
  transform: rotate(35deg);
}
.star1 {
  position: absolute;
  height: 0;
  width: 0;
  display: block;
  content: '';
  transform: rotate(-35deg);
}
.star2 {
  position: absolute;
  display: block;
  width: 0px;
  height: 0px;
  transform: rotate(-70deg);
  content: '';
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
