.auth-modal {
    z-index: auto;
}

.auth-modal .modal-content {
    /* z-index: 2; */
}

.modal-backdrop {
    z-index: auto;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
