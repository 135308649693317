.navbar-profile-name {
    padding: 10px;
}

.nav-icon {
    margin-right: 2px;
}

.brand-link {
    color: rgb(255, 255, 255);
}

.brand-link:hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.navbar-brand-img {
    width: 150px;
}

.navbar.navbar-light.navbar-expand-lg {
    transition: all 0.3s ease-in-out;
}

.navbar.navbar-light.navbar-expand-lg {
    z-index: 20;
}
.navbar.navbar-light.navbar-expand-lg.expanded {
    background-color: #faf9f5;
    /* border-bottom: solid 1px #efede6; */
    box-shadow: 0px 0px 6px gray;
    z-index: 20;
}

.nav-link.button {
    border-style: none;
    background-color: #ffffff00;
    text-align: left;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
