.spinnerWrapper {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  padding: 10px;
}

.header {
  margin-bottom: 20px;
}
.h1, .h2, .h3 {
  margin-bottom: 2px;
}
.h1 {
  font-size: 1.8rem;
  font-weight: 700;
}
.h2 {
  font-size: 1.2rem;
  font-weight: 600;
}
.h3 {
  font-size: 0.8rem;
  font-weight: 500;
}

.docData {
  margin-bottom: 20px;
}

.questions {
  margin-bottom: 12px;
}

.questionSec.hidden {
  display: none;
}

.questionTitle {
  font-size: 1.1rem;
  font-weight: 500;
}
.errorText {
  color: red;
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 8px;
}

.stepWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.stepButton {
  background-color: #3f8070;
  border-color: #3c7c6d;
}
.stepButton:active {
  background-color: #71a094;
}
.stepButton.precep {
  background-color: #4c6169;
  border-color: #3f5057;
}
.stepButton.precep:active {
  background-color: #697f88;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .main {
    flex: 1;
    padding-left: 50px; padding-right: 50px;
    display: flex;
    flex-direction: column;
  }
  .contentWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .content {
    width: 500px;
  }
  .forms {
    height: 100%;
  }
  .form {
    height: 100%;
  }
  .formContent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .questions {
    padding-bottom: 150px;
  }
  .footer {
    padding-top: 15px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #faf9f5;
    left: 0px;
    border-top: solid 1px #d8d7d2;
  }
}


@media only screen and (min-width: 1024px) {
}


@media only screen and (min-width: 1200px) {
}
