/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  DbView.module.css
*/

.container {
  /* border-top: solid rgb(235, 230, 211); */
  height: 100%;
}

.loadingWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  gap: 6px;
}

.docsContainer {
  padding-left: 10px; padding-right: 10px;
}

.docContainer {
  padding: 10px;
  border-bottom: solid #ebe6d3;
}

.docContainer:last-child {
  border-bottom-style: none;
}

.doc {
  border-style: none;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: black;
}

.h1 {
  font-size: 1.2rem;
  color: rgb(63, 128, 112);
  font-weight: 400;
  margin-bottom: 3px;
}

.h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 3px;
}

.h3 {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 3px;
}

.h3.indent {
  padding-left: 10px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
