/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  GuidelinesModal.module.css
*/

.header {
  /* display: flex; */
}
.brand {
  width: 100px;
}
.li {
  font-size: 0.9rem;
}
.bold {
  font-weight: 700;
  font-size: 1rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
