/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Template.css
*/

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item {
  border-bottom: solid 1px #efecde;
  padding-top: 5px; padding-bottom: 5px;
  justify-self: stretch;
  align-self: stretch;
  display: flex;
  align-items: center;
}
.name {
  border-right: solid 1px #efecde;
  padding-right: 5px;
}
.email {
  padding-left: 5px;
}
.item:last-child {
  border-bottom-style: none;
}
.item:nth-last-child(2) {
  border-bottom-style: none;
}
.itemContent {
  font-size: 0.8rem;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
