/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Template.css
*/
/* Screen size specs */

.title {
  font-size: 1.5rem;
  text-align: center;
  color: rgb(86, 86, 86);
}

.btnContainer {
  width: 100%;
}

.doneBtn {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.fpBtn, .caBtn {
  margin: 0px;
  padding: 0px;
}



@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .title {
    font-size: 1.2rem;
  }
}


@media only screen and (min-width: 1200px) {
}
