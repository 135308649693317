.flagsFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.flagContainer {
  background-color: #d0dedc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.1s ease-in-out;
  border-style: none;
  color: black;
}
.red {
  background-color: #efd8e1;
}

.flagContainer:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 2px rgb(187, 186, 186);
}

.flagContainer:active {
  background-color: #c2d3d1;
}
.red:active {
  background-color: #e9cdd8;
}

.flagText {
  user-select: none;
  text-align: left;
  flex: 1;
}

.flagCheck {
  background-color: white;
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
}

.flagCheck.checked {
  background-color: #3f8070;
  border-style: none;
}
.red .flagCheck.checked {
  background-color: #80473f;
}

.flagCheck i {
  color: white;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
