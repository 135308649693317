/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AboutPage.module.css
*/

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navWrapper {
  position: absolute;
  top: 0px;
  width: 100%;
}

.cover {
  height: 200px;
  object-fit: cover;
  background-image: linear-gradient(#4c6169, #0e2d37);
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  padding-top: 20px;
}
.imgContainer {
  height: 0px;
  width: 100%;
  padding-left: 15px;
}
.imgFlex {
  position: relative;
  top: -50px;
  display: flex;
  gap: 10px;
}
.img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px;
}
.caption {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 400;
  color: gray;
}

.contentWrapper {
  padding: 15px;
  padding-top: 85px;
}
.bold {
  font-weight: 700;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .imgContainer {
    padding: 0px;
    display: flex;
    justify-content: center;
  }
  .imgFlex {
    width: 600px;
  }
  .img {
    height: 120px;
    width: 120px;
  }
  .contentWrapper {
    display: flex;
    justify-content: center;
    padding-top: 90px;
  }
  .content {
    width: 600px;
    padding-top: 20px;
  }
  .title {
    padding-top: 10px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
