/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Template.css
*/

.brand {
  width: 150px;
  margin-right: 10px;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.linkWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.link {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}

.caption {
  text-align: center;
  font-size: 0.8rem;
  color: gray;
  margin-bottom: 0px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
