.bannerContainer {
  height: 320px;
  position: relative;
}

.navWrapper {
  position: absolute;
  top: 0px;
  width: 100vw;
}

.page {
  /* padding: 15px; */
  position: relative;
}

.content {
  /* padding: 15px; */
}

.searchWindowWrapper {
  padding: 15px;
}

.collectionSearch {
  margin-bottom: 20px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .bannerContainer {
    height: 350px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .searchWindowWrapper {
    width: 600px;
  }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
